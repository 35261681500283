import axios from "../api/axios";
import useAuth from "./useAuth";

const useRefreshToken = () => {
  const { setAuth } = useAuth();

  const refresh = async () => {
    const response = await axios.get("/api/token/refresh");
    setAuth({
      user: response.data.user,
    });
    return response.data.user;
  };
  return refresh;
};
export default useRefreshToken;
