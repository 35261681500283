import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Breadcrumb, Badge, Avatar, Dropdown, Menu } from "antd";
import { BellOutlined, DownOutlined } from "@ant-design/icons";
import "../css/topbar.css";
import { axiosPrivate } from "../api/axios";
import useAuth from "../hooks/useAuth";

function TopBar() {
  const location = useLocation();
  const navigate = useNavigate();
  const paths = location.pathname.split("/");
  let urls = "";

  const { auth } = useAuth();

  const logout = (e) => {
    e.preventDefault();
    axiosPrivate
      .post("/logout")
      .then((res) => {
        navigate("/login");
      })
      .catch((err) => {});
  };

  const menu = (
    <Menu>
      <Menu.Item key="0">
        <a href="#">Profile</a>
      </Menu.Item>
      <Menu.Item key="1">
        <a href="#" onClick={logout}>
          Logout
        </a>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="topbar">
      <div
        className="flex-header"
        style={{ height: "70px", width: "-webkit-fill-available" }}
      >
        <div className="breadcrumb">
          <Breadcrumb separator="/">
            {paths.map((key, value) => {
              if (key !== "") {
                urls += "/" + key;
                return (
                  <Breadcrumb.Item key={value}>
                    <Link to={urls}>{key.replace("-", " ")}</Link>
                  </Breadcrumb.Item>
                );
              } else {
                return "";
              }
            })}
          </Breadcrumb>
        </div>
        <div className="user-info">
          <Badge count={2}>
            <BellOutlined style={{ fontSize: "20px" }} />
          </Badge>
          <div className="flex-header">
            <Dropdown
              placement="bottomRight"
              overlay={menu}
              trigger={["click"]}
            >
              <span style={{ cursor: "pointer" }}>
                <Avatar
                  style={{
                    color: "#f56a00",
                    backgroundColor: "#fde3cf",
                    marginRight: "10px",
                  }}
                >
                  {auth?.user?.name.charAt(0).toUpperCase()}
                </Avatar>
                {auth?.user?.name} <DownOutlined />
              </span>
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
  );
}
export default TopBar;
